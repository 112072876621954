export default {
  ra: {
    boolean: {
      null: 'Not chosen',
    },
  },

  modals: {
    delete: {
      'title': 'Are you sure you want to delete this entity?',
      'text': 'This action cannot be undone.',
    }
  },

  date_pickers: {
    cancel: 'Cancel',
    ok: 'OK',
    clear: 'Clear'
  },

  languages: {
    english: "English",
    russian: "Russian",
  },

  roles: {
    merchant: "Merchant",
    admin: "Admin",
  },

  errors: {
    json: 'Incorrect JSON format'
  },

  filters: {
    search_by_id: "By ID",
    number_price_range_from: "Price from",
    number_price_range_to: "Price to",
    number_amount_range_from: "Amount from",
    number_amount_range_to: "Amount to",
    number_search_by_price_range: "By price",
    number_search_by_amount_range: "By amount",
    update_date_from: "Created date from",
    update_date_to: "Created date to",
    search_by_update_date: "By update date",
    created_date_from: "Created date from",
    created_date_to: "Created date to",
    search_by_create_date: "By created date",
    search_by_product: "By endpoints",
    search_by_payment_status: "By payment status",
    search_by_merchant: "By merchant",
    search_by_merchant_order: 'By merchant order',
    search_by_merchants: "By merchants",
    search_by_day: "By day",
    search_by_day_to: "By day to",
    search_by_status: "By status",
    search_by_http_status: "By http response code",
  },

  menu: {
    balance: 'Balance'
  },

  actions: {
    login: 'Login to your account',
    change_password: "Change my password",
    send_by_email: "Send by email",
  },

  forms: {
    change_password: {
      password: "Password",
      confirm_password: "Confirm password",
      submit: "Set new password",
    },
  },

  pages: {
    charts: {
      menu: 'Charts',
      title: 'Charts',
      chart: 'Chart',
      dimension: 'Dimension',
      granularity: 'Granularity',
      charts: {
        invoice_sum_price: 'Amount of successful payments',
        invoice_count: 'Count of successful payments',
        withdrawal_sum_amount: 'Amount of successful withdrawals',
        withdrawal_count: 'Count of successful withdrawals',
      },
      slice: {
        product: 'Endpoint',
        merchant: 'Merchant',
      }
    },
    dashboard: {
      menu: 'Dashboard',
      title: 'Dashboard',
    },
    faq: {
      menu: 'FAQ',
      title: 'FAQ',
      question1: '',
      answer1: '',
    },
    balances: {
      menu: 'Balances',
      title: 'Balances',
      merchant_balance_title: 'Balance',
      merchant_rolling_reserve_title: 'Rolling Reserve',
    },
  },

  resources: {

    'fbp_pricing/invoice': {
      name: 'Payment||||Payments',
      menu: 'Payments',
      fields: {
        id: "ID",
        price: 'Price',
        created_at: 'Created at',
        fee_from_merchant: 'Fee from merchant',
        customer_fee: 'Customer fee',
        merchant: "Merchant",
        primary_payment_gate: "Payment gate",
        payment_gate: "Payment gate",
        currency_code: "Currency code",
        data: "Data",
        external_id: "External ID",
        is_test: "Is test",
        last_payment_error: "Last payment error",
        last_refund_error: "Last refund error",
        logs: "Logs",
        merchant_order: "Merchant order",
        payment_status: "Payment status",
        payment_status_updated_at: "Payment status updated at",
        primary_product: "Endpoint",
        primary_terminal: "Primary terminal",
        refund_status: 'Refund status',
        refund_status_updated_at: "Refund status updated at",
        amount: "Amount",
        amount_paid: "Enroll",
      },
      statuses: {
        complete: 'Complete',
        failed: 'Failed',
        pending: 'Pending',
        refunded: 'Refunded',
        new: 'New',
        partial_complete: 'Partial complete',
      },
      refund_statuses: {
        complete: 'Complete',
        failed: 'Failed',
        pending: 'Pending',
        new: 'New',
      },
      refund: {
        open: "Refund",
        modal: {
          title: "Refund",
          text: "Confirm payment refund",
          submit: "Confirm",
          cancel: "Cancel",
          notify_requested: "Refund request is sended",
        }
      }
    },

    'fbp_pricing/refund': {
      name: 'Refund||||Refunds',
      menu: 'Refunds',
      fields: {
      }
    },

    'fbp_pricing/merchant': {
      name: 'Merchant||||Merchants',
      menu: 'Merchants',
      create: 'Create merchant',
      edit: 'Edit merchant',

      urlTypeInfo: {
        finish_url: "In this field, you can specify the address in the format https://url.com/finish, to which the user will be redirected after the payment is made",
        notification_url: "In this field, you can specify the address in the format https://url.com/notification, to which the result (notification) of the transaction will be sent",
      },

      fields: {
        id: "ID",
        urls: "URL",
        iname: "Name",
        name: "Name",
        api_secret: "API-secret",
        brand_name: "Brand name",
        company: "Company",
        is_test: "Test mode",
        is_available_for_invoice: 'Is available for invoice',
        is_available_for_withdrawal: 'Is available for withdrawal',
        person: "Person",
        primary_email: "Email",
        data: 'Data',
      },

      additional_settings: 'Additional settings'
    },

    'fbp_pricing/merchantcommission': {
      name: "Commission||||Commissions",
      create: 'Create Commission',
      edit: 'Edit commission',
      fields: {
        id: "ID",
        commission_object: "Commission object",
        commission_type: "Commission type",
        fix_amount: "Fix amount",
        merchant: "Merchant",
        min_amount: "Min amount",
        percent: "Percent",
        up_to: "Up to",
      },
      commission_types: {
        refund: 'Refund',
        invoice: 'Invoice',
        withdrawal: 'Withdrawal'
      }
    },

    'fbp_pricing/product': {
      name: 'Endpoint||||Endpoints',
      connected_products: 'Related endpoints',
      empty_connected_products: 'No related endpoints',
      menu: 'Endpoints',
      create: 'Create an Endpoint',
      edit: 'Edit an Endpoint',
      fields: {
        id: "ID",
        merchant: "Merchant",
        name: "Name",
        status: "Status",
        data: 'Data',
        is_available_for_invoice: 'Is available for invoice',
        is_available_for_withdrawal: 'Is available for withdrawal'
      },
      status: {
        new: "New"
      }
    },

    'fbp_pricing/productcommission': {
      name: "Commission||||Commissions",
      create: 'Create Commission',
      edit: 'Edit commission',
      fields: {
        id: "ID",
        commission_object: "Commission object",
        commission_type: "Commission type",
        fix_amount: "Fix amount",
        merchant: "Merchant",
        min_amount: "Min amount",
        percent: "Percent",
        up_to: "Up to",
      }
    },

    'fbp_pricing/productstatus': {
      name: 'Endpoint status||||Endpoints status',
      fields: {
        id: "ID",
        description: "Description",
        iname: "Name",
        name: "Name",
      },
      statuses: {
        new: 'New'
      }
    },

    'fbp_pricing/bulkwithdrawal': {
      name: 'Bulk withdrawal||||Bulk withdrawals',
      menu: 'Bulk withdrawal',
      edit: 'Edit bulk withdrawal',
      create: 'Create bulk withdrawal',
      download_registry: 'registry',
      download_registry_result: 'registry result',
      fields: {
        merchant_id: 'Merchant',
        endpoint_id: 'Endpoint',
        registry: 'File',
      }
    },

    'fbp_pricing/bulkwithdrawalitem': {
      name: 'Item||||Items'
    },

    'fbp_pricing/withdrawalrequest': {
      name: 'Withdrawal||||Withdrawals',
      menu: 'Withdrawals',
      fields: {
        id: "ID",
        amount: 'Amount',
        requested_amount: 'Requested amount',
        fee_from_merchant: 'Fee from merchant',
        status: 'Status',
        created_at: 'Created at',
        currency_code: "Сurrency сode",
        data: "Data",
        external_id: "External ID",
        finished_at: "Finished at",
        is_test: "Is test",
        logs: "Logs",
        merchant: "Merchant",
        merchant_order: "Merchant order",
        primary_payment_gate: "Payment gate",
        primary_product: "Endpoint",
      },
      statuses: {
        new: 'New',
        accepted: 'Accepted',
        pending: 'Pending',
        done: 'Done',
        rejected: 'Rejected',
        error: 'Error'
      }
    },

    'sbank/registryfile': {
      name: 'Registry||||Registries',
      menu: 'Registries',
      create_form: {
        start: "Start",
        end: "End",
        create_registry_file: "Create registry file",
        set_date_for_registry: "Set date for upload registry",
        confirm_create_registry_file: "Create registry",
      },
      download_registry_file: "Download Registry file",
      fields: {
        id: "ID",
        created_at: "Created at",
        day: "Day",
        day_to: "Day to",
        merchant: "Merchant",
        registry_file_url: "Registry file",
        registry_type: "Registry type",
      },

      types: {
        payment: 'Payments',
        withdrawal: 'Withdrawals',
      },

      creating_registry_message: 'Creating registry',
      created_registry_message: 'Registry is created',
      error_creating_registry_message: 'Registry is not created, try again',
    },

    'fbp_pricing/paymentgate': {
      fields: {
        iname: "iname",
      },
    },

    'sbank/settlement': {
      name: 'Settlement||||Settlements',
      menu: 'Settlements',
      fields: {
        id: "ID",
        amount: 'Amount',
        requested_amount: 'Requested amount',
        fee_from_merchant: 'Fee from merchant',
        status: 'Status',
        created_at: 'Created at',
        currency_code: "Сurrency сode",
        data: "Data",
        external_id: "External ID",
        finished_at: "Finished at",
        is_test: "Is test",
        logs: "Logs",
        merchant: "Merchant",
        merchant_order: "Merchant order",
        primary_payment_gate: "Payment gate",
        primary_product: "Endpoint",
      },
    },

    'fbp_pricing/terminal': {
      name: 'Terminal||||Terminals',
      create: 'Create terminal',
      edit: 'Edit terminal',
      menu: 'Terminals',
      fields: {
        id: "ID",
        'data.portal_id': 'Name',
        current_day_amount: 'Current day amount',
        current_day_count: 'Current day count',
        current_month_amount: 'Current month amount',
        current_month_count: 'Current month count',
        limit_card_day_amount: 'Limit card day amount',
        limit_card_day_count: 'Limit card day count',
        limit_card_withdrawal_day_amount: 'Limit card withdrawal day amount',
        limit_card_withdrawal_day_count: 'Limit card withdrawal day count',
        limit_day_amount: 'Limit day amount',
        limit_day_count: 'Limit day count',
        limit_month_amount: 'Limit month amount',
        limit_month_count: 'Limit month count',
        min_amount: 'Min amount',
        payment_gate: 'Payment gate',
        status: 'Status',
        terminal_owner: 'Terminal owner',
        data: 'Data',
      },
      statuses: {
        active: 'Active',
        disabled: 'Disabled',
        deleted: 'Deleted',
      }
    },

    'fbp_pricing/terminalowner': {
      name: 'Terminal owner||||Terminal owners',
      create: 'Create terminal owner',
      edit: 'Edit terminal owner',
      menu: 'Terminal owners',
      fields: {
        id: "ID",
        iname: 'Name',
        limit_card_day_count: 'Limit card day count',
        limit_card_withdrawal_day_count: 'Limit card withdrawal day count',
      },
    },

    'fbp_webhook/webhook': {
      name: 'Webhook||||Webhooks',
      create: 'Create webhook',
      edit: 'Edit webhook',
      menu: 'Webhooks',
      fields: {
        created_at: 'Created at',
        product: 'Endpoint',
        merchant: 'Merchant',
        url: 'Url',
        method: 'Method',
        retry_count: 'Retry count',
        status: 'Status',
        webhook_type: 'Webhook type',
        response_http_status: 'Response code',
        response_body: 'Response body',
        merchant_order: 'Merchant order',
        invoice_id: 'Invoice id',
        withdrawal_request_id: 'Withdrawal id',
        next_retry_time: 'Next retry time'
      },
      send_webhooks_action: 'Retry'
    },

    'sbank/sbankuser': {
      name: 'User||||Users',
      create: 'Create user',
      edit: 'Edit user',
      menu: 'Users',
      fields: {
        id: "ID",
        username: 'Username',
        merchants: 'Merchants',
      },
    },

    'sbank/invoicescreenshot': {
      name: 'Invoice screenshot||||Invoice screenshots',
      create: 'Add invoice screenshot',
      edit: 'Edit invoice screenshot',
      menu: 'Invoice screenshots',
      download: 'download',
      upload: 'Upload invoice',
      fields: {
        id: "ID",
        status: "Invoice screenshot",
        updated_at: "Updated at",
        approved_at: "Approved at",
        rejected_reason: "Rejected reason",
        screenshot_url: "File",
      },
    },

  },

  common: {
    yes: 'Yes',
    no: 'No'
  }
}
