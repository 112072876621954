import {
  FunctionField,
  ReferenceField,
  ReferenceInput,
  SimpleForm,
  TextInput,
  useEditController,
  useTranslate,
  Datagrid,
  useNotify,
  List,
  TextField,
  DateField,
  refreshView,
} from "react-admin";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { JsonEditor } from "../fields/JsonEditor/JsonEditor";
import { Box, Button, TextField as MuiTextField } from "@material-ui/core";
import { CurrencyField } from "../fields/CurrencyField/CurrencyField";
import { Stack } from "@mui/material";
import { PermissionField } from "../PermissionField/PermissionField";
import { addPermissionLabel } from "../../utils/addPermissionLabel";
import StatusField from "../fields/StatusField/StatusField";
import { colorMapping, paymentStatusIDs } from "../../configs/paymentStatuses";
import DateTimeInput from "../inputs/DatePickers/DateTimeInput";
import dataProvider, { rawDataProvider } from "../../providers/dataProvider";
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import { sign } from "../../utils/signature";
import { secureApi } from "../../utils/apiURL";
import { refundStatusColorMapping } from "../../configs/refundStatuses";
import DownloadField from "../fields/DownloadField/DownloadField";
import UploadIcon from "@mui/icons-material/Upload";

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: '100vw',
    // maxHeight: 'calc(100vh - 48px)',
    position: "relative",
    // top: 48,
    overflow: "auto",
    width: 500,
    display: "flex",
    flexDirection: "column"
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '12px 24px 0 24px',
  },
  form: {
    padding: '0 4px',
  },
  outlined: {
    padding: '1px'
  },
  box: {
    position: 'relative',
    border: '1px solid rgba(0, 0, 0, .23)',
    borderRadius: '4px',
    padding: '12.5px 14px'
  },
  label: {
    position: 'absolute',
    top: -3,
    left: 0,
    color: 'rgba(0, 0, 0, 0.38)',
    fontSize: '1rem',
    // fontWeight: 500,
    marginBottom: '6px',
    transform: 'translate(14px, -6px) scale(0.75)',
    transformOrigin: 'top left',
    backgroundColor: '#fff',
    zIndex: 100,
    padding: '0 3px'
  },
  dialogActions: {
    marginTop: '24px'
  },
  refund: {
    margin: '0 20px 20px'
  },
  screenShots: {
    margin: '0 20px',
    '& th': {
      padding: '4px !important',
      lineHeight: '1rem !important',
      fontSize: '0.75rem !important',
      pointerEvents: 'none',
    },
    '& td': {
      padding: '4px !important',
      '& > span': {
        lineHeight: '1rem !important',
        fontSize: '0.75rem !important',
      }
    }
  }
}))

export function PaymentView(props) {
  const {basePath, resource, id, onCancel} = props
  const classes = useStyles()
  const translate = useTranslate()
  const notify = useNotify()
  const [merchantData, setMerchantData] = React.useState(null)
  const [openDialog, setOpenDialog] = React.useState(false)
  const [refundVisible, setRefundVisiblen] = React.useState(false)
  const [refundAmount, setRefundAmount] = React.useState("")

  const [screenShotsKey, setScreenShotsKey] = React.useState(Date.now().toString())
  const [isFileLoading, setIsFileLoading] = React.useState(false)

  function openFile() {
    const fileInput = document.createElement("input")
    const readFile = async function(e) {
      e.stopPropagation();
      e.preventDefault();
      const file = e.target.files[0];
      if(!file) {
        return
      }
      setIsFileLoading(true)
      await dataProvider.create('sbank/invoicescreenshot', {
        invoice: props.id,
        data: {
          invoice: props.id,
          'screenshot|rawFile': {rawFile: file},
        }
      })
      setIsFileLoading(false)
      setScreenShotsKey(Date.now().toString())
    }
    fileInput.type='file'
    fileInput.onchange= readFile
    fileInput.click()
  }

  const controllerProps = useEditController({
    basePath: `${basePath}`,
    resource: 'fbp_pricing/invoice',
    id,
    undoable: false,
  })

  const paymentStatus = controllerProps?.record?.payment_status
  const price = controllerProps?.record?.price
  const merchant = controllerProps?.record?.merchant
  const invoice_id = controllerProps?.record?.id
  const apiSecret = merchantData?.api_secret

  const refundDisabled = !merchantData
    || ['0', '0.', ""].includes(refundAmount)

  React.useEffect(() => {
    if (merchant) {
      dataProvider.getOne("fbp_pricing/merchant", {id: merchant})
        .then(({data}) => {
          setMerchantData(data)
        });
    }
    setRefundAmount(Number(price).toString())
  }, [controllerProps?.record]);

  const onRefund = () => {
    !refundVisible
      ? setRefundVisiblen(true)
      : setOpenDialog(true)
  }
  const onCloseDialog = () => setOpenDialog(false)
  const onChangeAmount = (e) => {
    const value = e.target.value
    if (!(/^[0-9]*\.?[0-9]*$/).test(value)) {
      return
    }
    if (value[0] === '0' && !isNaN(Number(value[1]))) {
      return
    }
    if (value[0] === '.') {
      return
    }
    if (Number(value) > Number(price)) {
      return
    }
    setRefundAmount(value)
  }

  const onSubmitRefund = () => {
    const payload = {
      amount: refundAmount,
      merchant_id: merchant.toString(),
      invoice_id: invoice_id.toString(),
    }
    const signature = sign(payload, apiSecret, {
      PATH: '/refund',
      POST: {}
    })

    const res = rawDataProvider("CUSTOM_POST", `${secureApi}/refund`, {
      ...payload,
      signature
    })
    res.then(async (data) => {
      if (data.json.status === 'success') {
        notify(translate(`resources.fbp_pricing/invoice.refund.modal.notify_requested`), {type: 'warning'}, {autoHideDuration: 16000});
      } else {
        notify(data.json.message, {type: 'warning'}, {autoHideDuration: 16000});
      }
      onCloseDialog()
    }).catch(async error => {
      console.log(error);
    })
  }

  const RefundDialog = () => {
    return (
      <Dialog
        open={openDialog}
        onClose={onCloseDialog}
      >
        <DialogTitle>
          {translate(`resources.fbp_pricing/invoice.refund.modal.title`)}
        </DialogTitle>

        <DialogContent>
          {translate(`resources.fbp_pricing/invoice.refund.modal.text`)}
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button
            onClick={onSubmitRefund}
            color="primary"
            variant="contained"
            fullWidth
            disabled={refundDisabled}
          >
            {translate(`resources.fbp_pricing/invoice.refund.modal.submit`)}
          </Button>
          <Button
            onClick={onCloseDialog}
            color="primary"
            variant="outlined"
            fullWidth
          >
            {translate(`resources.fbp_pricing/invoice.refund.modal.cancel`)}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  return (
    <div className={classes.root}>
      <div className={classes.title}>
        <Typography variant="h6">
          {translate(`resources.fbp_pricing/invoice.name`, {smart_count: 1})} #{id}
        </Typography>
        <IconButton onClick={onCancel}>
          <CloseIcon/>
        </IconButton>
      </div>

      <div className={classes.form}>
        <SimpleForm
          className={classes.form}
          basePath={controllerProps.basePath}
          record={{
            ...controllerProps.record,
            iname: merchant
          }}
          save={controllerProps.save}
          version={controllerProps.version}
          resource={resource}
          toolbar={null}
        >
          <TextInput
            variant={'outlined'}
            disabled
            fullWidth
            source="iname"
            label={translate(`resources.fbp_pricing/invoice.fields.merchant`)}
          />

          <ReferenceInput variant={'outlined'} fullWidth source="primary_product" reference="fbp_pricing/product"
                          link={true} sortable={false}>
            <TextInput variant={'outlined'} disabled fullWidth source="name"/>
          </ReferenceInput>

          <TextInput fullWidth disabled variant="outlined" source="merchant_order"/>

          <Stack
            style={{width: 'auto'}}
            direction="row"
            justifyContent="space-between"
            alignItems="stretch"
            spacing={2}
            sx={{marginBottom: 3}}
          >
            <Box sx={{flexGrow: 1, flexBasis: 100}}>
              <CurrencyField resource={resource} source="price" codeSource='currency_code'/>
            </Box>
            <Box sx={{flexGrow: 1, flexBasis: 100}}>
              <CurrencyField resource={resource} source="amount_paid" codeSource='currency_code'/>
            </Box>
          </Stack>

          <Stack
            style={{width: 'auto'}}
            direction="row"
            justifyContent="space-between"
            alignItems="stretch"
            spacing={2}
            sx={{marginBottom: 3}}
          >
            <Box sx={{flexGrow: 1, flexBasis: 100}}>
              <CurrencyField resource={resource} source="fee_from_merchant" codeSource='currency_code'/>
            </Box>
            <Box sx={{flexGrow: 1, flexBasis: 100}}>
              <CurrencyField resource={resource} source="customer_fee" codeSource='currency_code'/>
            </Box>
          </Stack>

          <PermissionField
            source="primary_payment_gate"
            // addLabel={addPermissionLabel(resource, "primary_payment_gate")}
            variant={'outlined'}
            disabled
            fullWidth
          >
            <ReferenceInput
              source="primary_payment_gate"
              reference="fbp_pricing/paymentgate"
              link={false}
              sortable={false}
            >
              <TextInput variant={'outlined'} source="iname" sortable={false}/>
            </ReferenceInput>
          </PermissionField>

          <PermissionField
            source="external_id"
            //addLabel={addPermissionLabel(resource, "external_id")}
            variant={'outlined'}
            disabled
            fullWidth
          >
            <TextInput source="external_id"/>
          </PermissionField>


          <Stack
            style={{width: 'auto'}}
            direction="row"
            justifyContent="space-between"
            alignItems="stretch"
            spacing={2}
            sx={{marginBottom: 1}}
          >
            <Box className={classes.box} sx={{flexGrow: 1, flexBasis: 100}}>
              <div className={classes.label}>{translate(`resources.fbp_pricing/invoice.fields.payment_status`)}</div>
              <ReferenceField
                source="payment_status"
                reference="fbp_pricing/paymentstatus"
                link={false}
                sortable={false}
                variant={'outlined'}
                className={classes.outlined}
                addLabel={false}
              >
                <StatusField className={classes.outlined} colorMap={colorMapping}
                             translationKey={`resources.fbp_pricing/invoice.statuses`} source="name"/>
              </ReferenceField>
            </Box>
            <Box className={classes.box} sx={{flexGrow: 1, flexBasis: 100}}>
              <div className={classes.label}>
                {translate(`resources.fbp_pricing/invoice.fields.refund_status`)}
              </div>
              <ReferenceField
                source="refund_status"
                reference="fbp_pricing/refundstatus"
                link={false}
                sortable={false}
                variant={'outlined'}
                className={classes.outlined}
                addLabel={false}
              >
                <StatusField
                  className={classes.outlined}
                  colorMap={refundStatusColorMapping}
                  translationKey={`resources.fbp_pricing/invoice.refund_statuses`}
                  source="name"
                />
              </ReferenceField>
            </Box>
          </Stack>

          <DateTimeInput disabled fullWidth variant="outlined" source="payment_status_updated_at" options={{ format: 'dd.MM.yyyy, HH:mm:ss', ampm: false, clearable: true, disabled: true, width: '100%' }} />

          <DateTimeInput style={{marginBottom: 16}} disabled fullWidth variant="outlined" source="refund_status_updated_at" options={{ format: 'dd.MM.yyyy, HH:mm:ss', ampm: false, clearable: true, disabled: true, width: '100%' }} />

          <TextInput style={{marginTop: 24}} fullWidth disabled variant="outlined" source="last_payment_error"/>

          <TextInput fullWidth disabled variant="outlined" source="last_refund_error"/>

          <Stack
            style={{width: 'auto'}}
            direction="column"
            justifyContent="space-between"
            alignItems="stretch"
            spacing={1}
            sx={{marginBottom: 1}}
          >
            <Box className={classes.box} sx={{flexGrow: 1}}>
              <div className={classes.label}>{translate(`resources.fbp_pricing/invoice.fields.is_test`)}</div>
              <FunctionField variant="outlined" source="is_test"
                             render={record => translate(`common.${record.is_test ? 'yes' : 'no'}`)}/>
            </Box>
          </Stack>

          <PermissionField
            source="data"
            addLabel={addPermissionLabel(resource, "data")}
            fullWidth
          >
            <JsonEditor disabled={true}/>
          </PermissionField>

          <PermissionField
            source="logs"
            variant="outlined"
            fullWidth
            multiline
          >
            <TextInput variant="outlined" style={{width: '100%'}} multiline maxRows={8} disabled={true}/>
          </PermissionField>
        </SimpleForm>
        {paymentStatus == paymentStatusIDs.complete &&
          <div className={classes.refund}>
            <Stack
              style={{width: 'auto'}}
              justifyContent="space-between"
              alignItems="stretch"
              sx={{marginBottom: 1}}
            >
              <Box className={classes.box} sx={{display: 'flex', gap: '8px'}}>
                {refundVisible && (
                  <MuiTextField
                    variant={'outlined'}
                    fullWidth
                    label={translate(`resources.fbp_pricing/invoice.fields.amount`)}
                    helperText={false}
                    value={refundAmount}
                    onChange={onChangeAmount}
                    size="small"
                  />
                )}
                <Button
                  onClick={onRefund}
                  color="primary"
                  variant="contained"
                  disabled={refundDisabled}
                  fullWidth
                >
                  {translate(`resources.fbp_pricing/invoice.refund.open`)}
                </Button>
              </Box>
            </Stack>
            <RefundDialog/>
          </div>
        }

        <Typography
          variant="h5"
          component="h5"
          style={{margin: '0 20px 16px 20px'}}
        >
          {translate(`resources.sbank/invoicescreenshot.menu`)}
        </Typography>

        <div style={{marginTop: '-72px', marginBottom: '24px'}}>
          <List
            key={screenShotsKey}
            {...props}
            className={classes.screenShots}
            empty={false}
            exporter={false}
            bulkActionButtons={false}
            sort={{field: 'created_at', order: 'DESC'}}
            pagination={null}
            perPage={1e4}
            resource={"sbank/invoicescreenshot"}
          >
            <Datagrid rowClick={() => null}>
              <TextField source="id"/>
              <TextField source="status"/>
              <DateField source="updated_at"/>
              <DateField source="approved_at"/>
              <TextField source="rejected_reason"/>
              <DownloadField
                source="screenshot_url"
                sortable={false}
                label={''}
              />
            </Datagrid>
          </List>

          <Button
            full-width
            color="primary"
            variant="outlined"
            style={{margin: '16px 20px 0 20px'}}
            onClick={openFile}
            startIcon={<UploadIcon/>}
            disabled={isFileLoading}
          >
            {translate(('resources.sbank/invoicescreenshot.upload'))}
          </Button>
        </div>
      </div>
    </div>
  )
}
