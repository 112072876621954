import * as React from "react";
import PropTypes from 'prop-types';
import {useRecordContext, useTranslate} from 'react-admin';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import {Button} from "@material-ui/core";

function download(url) {
  const a = document.createElement('a')
  a.href = url
  a.download = url.split('/').pop()
  a.target="_blank"
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
}

const DownloadField = (props) => {
  const {source, label, } = props;
  const record = useRecordContext(props);
  const translate = useTranslate()

  if (record[source] === null) {
    return (<span>-</span>)
  }

  return (
    <span>
      {label ? (
        <Button
          onClick={() => download(record[source])}
          variant="text"
          startIcon={<FileDownloadIcon />}
        >
          {label}
        </Button>
      ) : (
        <Button
          onClick={() => download(record[source])}
        >
          <FileDownloadIcon />
        </Button>
      )}
    </span>
  )
}

DownloadField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

export default DownloadField;
